<template>
  <div>
    <el-form label-width="120px">
      <el-form-item label="物流类型：">上门自提</el-form-item>
      <el-form-item
        class="is-required"
        label="自提点名称："
      >
        <el-input
          style="width: 500px;"
          v-model="name"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="is-required"
        label="自提点地址："
      >
        <el-button style="min-width: 300px;" @click="showFlag = true">{{addressDetail==='' ? '请选择地址' : addressDetail}}</el-button>
      </el-form-item>
      <el-form-item>
      </el-form-item>

      <el-form-item
        label="联系电话："
        class="is-required"
      >
        <el-input
          v-model="areaCode"
          style="width: 100px;"
        ></el-input>
        -
        <el-input
          v-model="phone"
          style="width: 400px;"
          placeholder="请输入准确的联系电话，便于买家联系（区号可留空）"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="接待时间："
        class="is-required"
      >
        <el-time-picker
          is-range
          v-model="receptionTime"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        >
        </el-time-picker>
        <div style="margin: 10px 0;">
          <el-tag
            v-for="(item,index) in receptionWeekdays"
            :key="index"
            :style="{color:item.status?'#26C6DA':'#333333',borderColor:item.status?'rgba(38,198,218,0.08)':'rgba(226, 226, 226, 0.5)',backgroundColor:item.status?'rgba(38,198,218,0.08)':'#fff',marginRight: '10px',}"
            @click="item.status=!item.status"
          >{{item.week}}</el-tag>
        </div>
        <!-- <div>
          <el-button type="text">确定</el-button>
          <el-button type="text">删除</el-button>
        </div> -->
      </el-form-item>

      <el-form-item
        label="自提时间："
        class="is-required"
      >
        <el-checkbox
          :true-label="1"
          :false-label="0"
          v-model.number="selfPickTimeRequired"
        >需要买家选择自提时间</el-checkbox>
        <div style="font-size:12px;color:#999999">注：勾选后，买家下单选择上门自提，必须选择自提时间，卖家需要按约定时间备货。不勾选，将会提示买家尽快到店自提</div>
      </el-form-item>

      <el-form-item
        label="自提时段："
        v-if="selfPickTimeRequired&&selfPickTimeRequiredAdd"
        class="is-required"
      >
        <el-time-picker
          is-range
          v-model="selfMentionTime"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        >
        </el-time-picker>
        <div style="margin: 10px 0;">
          <el-tag
            v-for="(item,index) in selfMentionWeekdays"
            :key="index"
            :style="{color:item.status?'#26C6DA':'#333333',borderColor:item.status?'rgba(38,198,218,0.08)':'rgba(226, 226, 226, 0.5)',backgroundColor:item.status?'rgba(38,198,218,0.08)':'#fff',marginRight: '10px',}"
            @click="item.status=!item.status"
          >{{item.week}}</el-tag>
        </div>
        <!-- <div>
          <el-button type="text">确定</el-button>
          <el-button type="text">删除</el-button>
        </div> -->
      </el-form-item>
      <el-form-item
        label="自提时段："
        v-if="selfPickTimeRequired&&!selfPickTimeRequiredAdd"
        class="is-required"
      >
        <div
          style="font-size:13px;color:#26C6DA"
          @click="handleClickAddTime()"
        >新增时间段</div>
        <div style="font-size:13px;color:#EB4646">请至少选择一个自提时间</div>
      </el-form-item>
      <el-form-item
        label="时段细分："
        v-if="selfPickTimeRequired"
        class="is-required"
      >
        <el-radio-group v-model.number="timeSpan">
          <el-radio :label="0">
            天
          </el-radio>
          <el-radio :label="1">
            上午下午晚上（12:00和18:00作为分界）
          </el-radio>
          <el-radio :label="2">
            小时
          </el-radio>
          <el-radio :label="3">
            半小时
          </el-radio>
        </el-radio-group>
        <div style="font-size:12px;color:#999999">注：买家可选的自提时间会根据时段进行细分。</div>
      </el-form-item>

      <el-form-item
        label="预约自提："
        v-if="selfPickTimeRequired"
        class="is-required"
      >
        <el-radio-group v-model.number="aheadMinType">
          <el-radio
            :label="0"
            v-model="aheadMin"
          >
            无需自提
          </el-radio>
          <el-radio :label="1">
            提前
            <el-input
              :disabled="aheadMinType!==1"
              v-model="aheadMin"
              style="width: 80px;"
            ></el-input>
            天
          </el-radio>
          <el-radio :label="2">
            提前
            <el-input
              :disabled="aheadMinType!==2"
              v-model="aheadMin"
              style="width: 80px;"
            ></el-input>
            时
          </el-radio>
          <el-radio :label="3">
            提前
            <el-input
              :disabled="aheadMinType!==3"
              v-model="aheadMin"
              style="width: 80px;"
            ></el-input>
            分
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="最长预约："
        v-if="selfPickTimeRequired"
        class="is-required"
      >
        <el-radio-group v-model.number="aheadMaxType">
          <el-radio
            :label="0"
            v-model="aheadMax"
          >
            只能当天提
          </el-radio>
          <el-radio :label="1">
            可预约
            <el-input
              v-model="aheadMax"
              style="width: 80px;"
            ></el-input>
            天内订单
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="自提点照片："
        class="is-required"
      >
        <iyy-upload
          :file-type="1"
          :file-list.sync="images"
          :limit="30"
        ></iyy-upload>
      </el-form-item>
      <el-form-item label="商家推荐：">
        <el-input
          v-model="description"
          type="textarea"
          :autosize="{minRows: 3}"
          show-word-limit
          maxlength="30"
          style="width: 500px;"
        ></el-input>
        <!-- <div style="margin: 10px 0;">
          <el-checkbox>同时作为门店推荐</el-checkbox>
        </div> -->
      </el-form-item>
    </el-form>
    <div class="btn-wrap">
      <el-button
        type="default"
        @click="$router.go(-1)"
      >返回</el-button>
      <el-button
        type="primary"
        @click="saveAll"
      >保存</el-button>
    </div>
    <iyy-mapCode :visible.sync="showFlag" :address="addressDetail" :location="location" @select="handleChoose" />
  </div>
</template>

<script>
// import area from '// util/area'
// import IyyMapCode from '@/components/IyyMapCode'
// import IyyUpload from '// components/IyyUpload'
import { AddDeliverySelfPickPoint, DeliverySelfPickPoint, UpdateDeliverySelfPickPoint } from '@/api/marketing/shop/set'
export default {
  components: { IyyUpload, IyyMapCode },
  data () {
    const timeSpanList = ['day', 'meal', 'hour', 'halfHour']
    const aheadMinTypeList = ['none', 'day', 'hour', 'minute']
    return {
      showFlag: false,
      timeSpanList,
      aheadMinTypeList,
      name: '',
      timeSpan: 0,
      area,
      address: '',
      location: {},
      addressDetail: '',
      receptionTime: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      receptionWeekdays: [
        { status: 0, week: '周一' },
        { status: 0, week: '周二' },
        { status: 0, week: '周三' },
        { status: 0, week: '周四' },
        { status: 0, week: '周五' },
        { status: 0, week: '周六' },
        { status: 0, week: '周日' }
      ],
      areaCode: '',
      phone: '',
      selfMentionTime: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      selfMentionWeekdays: [
        { status: 0, week: '周一' },
        { status: 0, week: '周二' },
        { status: 0, week: '周三' },
        { status: 0, week: '周四' },
        { status: 0, week: '周五' },
        { status: 0, week: '周六' },
        { status: 0, week: '周日' }
      ],
      aheadMinType: 0,
      aheadMin: 0,
      aheadMaxType: 0,
      aheadMax: 0,
      images: [],
      // imagesReal:[],
      description: '',
      upload: {
        action: '',
        uploadParam: {}
      },
      dialogImageUrl: '',
      // dialogVisible: false,
      map: null,
      searchService: null,
      markers: [],
      selfPickTimeRequired: 0,
      selfPickTimeRequiredAdd: 0
    }
  },
  methods: {
    init () {
      if (this.$route.params.uuid && this.$route.params.uuid !== '') {
        DeliverySelfPickPoint(this.$route.params.uuid).then((res) => {
          this.name = res.data.name
          // this.address = res.data.province + '/' + res.data.city + '/' + res.data.district
          this.addressDetail = res.data.address
          this.location = { lat: res.data.lat, lng: res.data.lng }
          let receptionTime = res.data.openingTime[0]
          this.selfPickTimeRequiredAdd = res.data.selfPickTimeRequired ? 1 : 0
          this.receptionTime = [
            new Date(2016, 9, 10, receptionTime.startAt.split(':')[0], receptionTime.startAt.split(':')[1]),
            new Date(2016, 9, 10, receptionTime.endAt.split(':')[0], receptionTime.endAt.split(':')[1])
          ]
          let arrString = receptionTime.weekdays.split('')
          let Weekdays = this.receptionWeekdays.map((item, index) => {
            item.status = Number(arrString[index])
            return item
          })
          this.receptionWeekdays = Weekdays
          this.areaCode = res.data.areaCode
          this.phone = res.data.phone
          this.selfPickTimeRequired = res.data.selfPickTimeRequired
          let selfMentionTime = res.data.time || []
          this.selfMentionTime = [
            new Date(2016, 9, 10, selfMentionTime[0].startAt.split(':')[0], selfMentionTime[0].startAt.split(':')[1]),
            new Date(2016, 9, 10, selfMentionTime[0].endAt.split(':')[0], selfMentionTime[0].endAt.split(':')[1])
          ]
          let arrString2 = selfMentionTime[0].weekdays.split('')
          let Weekdays2 = this.selfMentionWeekdays.map((item, index) => {
            item.status = Number(arrString2[index])
            return item
          })
          this.selfMentionWeekdays = Weekdays2
          this.timeSpan = this.timeSpanList.indexOf(res.data.timeSpan)
          this.aheadMinType = this.aheadMinTypeList.indexOf(res.data.aheadMinType)
          this.aheadMin = res.data.aheadMin
          this.aheadMaxType = res.data.aheadMax === 0 ? 0 : 1
          this.aheadMax = res.data.aheadMax
          this.images = res.data.images
          this.description = res.data.description
        })
      }
    },
    handleClickAddTime () {
      this.selfPickTimeRequiredAdd = 1
    },
    handleSelect (value) {
      this.addressDetail = value.address
      this.location = value.location
    },

    getTime (date) {
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':'
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      return h + m
    },
    handleChoose (data) {
      this.address = data.provinces
      this.addressDetail = data.address
      this.location.lat = data.lat
      this.location.lng = data.lng
    },
    saveAll () {
      var arr = this.receptionWeekdays.map(function (item) {
        return Number(item.status)
      })
      var arrSelf = this.selfMentionWeekdays.map(function (item) {
        return Number(item.status)
      })
      let obj = {
        name: this.name,
        province: this.address.province,
        city: this.address.city,
        district: this.address.district,
        address: this.addressDetail,
        lat: this.location.lat,
        lng: this.location.lng,
        openingTime: [{
          startAt: this.getTime(this.receptionTime[0]),
          endAt: this.getTime(this.receptionTime[1]),
          weekdays: arr.join('')
        }],
        areaCode: this.areaCode,
        phone: this.phone,
        selfPickTimeRequired: Number(this.selfPickTimeRequired),
        time: [{
          startAt: this.getTime(this.selfMentionTime[0]),
          endAt: this.getTime(this.selfMentionTime[1]),
          weekdays: arrSelf.join('')
        }],
        timeSpan: this.timeSpanList[this.timeSpan],
        aheadMinType: this.aheadMinTypeList[this.aheadMinType],
        aheadMin: Number(this.aheadMinType === 0 ? 0 : this.aheadMin),
        aheadMax: Number(this.aheadMaxType === 0 ? 0 : this.aheadMax),
        images: this.images,
        description: this.description
      }

      if (this.$route.params.uuid && this.$route.params.uuid !== '') {
        UpdateDeliverySelfPickPoint(this.$route.params.uuid, obj).then(res => {
          if (res.code === 0) {
            this.$message.success('修改成功')
          }
        })
      } else {
        AddDeliverySelfPickPoint(obj).then(res => {
          if (res.code === 0) {
            this.$message.success('新增成功')
          }
        })
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>
</style>
